export const comments = {
  "ja": {
    "score": {
      "up": "生活習慣スコアは9月より%d点上がりました。その調子です！今年も残り2か月です。良い生活習慣はこのまま継続してくださいね。<br>\n11月のテーマは、<b>❝“冷え”に負けないココロとカラダ❞</b>​   です。冷え性の悩みが増える季節になりました。冷えの原因は、血液の流れが滞ってしまうことやストレスなどがあります。心身共に温めて、冬を乗り切りましょう。\n\n",
      "down": "生活習慣スコアは9月より%d点下がりました。今年も残り2か月。年末にかけて忙しくなる時期です。健康管理を気にかけて体調を整えましょう。<br>\n11月のテーマは、<b>❝“冷え”に負けないココロとカラダ❞​</b>   です。冷え性の悩みが増える季節になりました。冷えの原因は、血液の流れが滞ってしまうことやストレスなどがあります。心身共に温めて、冬を乗り切りましょう。\n\n",
      "equal100": "生活習慣スコアは9月に引き続き%満点%です。大変素晴らしいです。今年も残り2か月です。良い生活習慣はこのまま継続してくださいね。<br>\n11月のテーマは、<b>❝“冷え”に負けないココロとカラダ❞​</b>   です。冷え性の悩みが増える季節になりました。冷えの原因は、血液の流れが滞ってしまうことやストレスなどがあります。心身共に温めて、冬を乗り切りましょう。\n\n",
      "equal": "生活習慣スコアは9月と同じ点数です。今年も残り2か月。年末にかけて忙しくなる時期です。健康管理を気にかけて体調を整えましょう。<br>\n11月のテーマは、<b>❝“冷え”に負けないココロとカラダ❞​</b>   です。冷え性の悩みが増える季節になりました。冷えの原因は、血液の流れが滞ってしまうことやストレスなどがあります。心身共に温めて、冬を乗り切りましょう。\n\n"
    },
    "steps": {
      "up": "歩数スコアは9月より%d点上がりました。<br>\nこの調子です！歩くと全身の筋肉をしっかり動かすことができ、血流の促進にもつながります。\n\n",
      "down": "歩数スコアは9月より%d点下がりました<br>\nまずは、今より1,000歩（約10分）歩数を増やすことを意識してみませんか？\n\n",
      "equal": "歩数スコアは9月と同じ点数です。<br>\n歩くと全身の筋肉をしっかり動かすことができ、血流の促進にもつながります。\n\n"
    },
    "exercise": {
      "up": "運動のスコアは9月より%d点上がりました。<br>\nスポーツの秋を満喫していますか？運動には、体力や筋力がつくこと以外に、気分転換やストレス解消など「心の健康効果」もあります♪\n\n",
      "down": "運動のスコアは9月より%d点下がりました。<br>\n運動には、ストレス解消など「心の健康効果」もあります♪特にウォーキングなどの有酸素運動がストレス緩和に適していると言われていますよ。\n\n",
      "equal": "運動のスコアは9月と同じ点数です。<br>\n運動には、ストレス解消など「心の健康効果」もあります♪特にウォーキングなどの有酸素運動がストレス緩和に適していると言われていますよ。\n\n"
    },
    "meal": {
      "up": "食事のスコアは9月より%d点上がりました。<br>\nよく噛むことも冷え対策の1つです。<a href=\"https://go.andwell.jp/library/6f2852b4-32ca-11ee-9fa9-06528b823331\">こちらのコラム</a>を読んで、よく噛んでゆっくり食べるコツをつかもう！\n\n",
      "down": "食事のスコアは9月より%d点下がりました。<br>\nよく噛むことも冷え対策の1つです。<a href=\"https://go.andwell.jp/library/6f2852b4-32ca-11ee-9fa9-06528b823331\">こちらのコラム</a>を読んで、よく噛んでゆっくり食べるコツをつかもう！\n\n",
      "equal": "食事のスコアは9月と同じ点数です。<br>\nよく噛むことも冷え対策の1つです。<a href=\"https://go.andwell.jp/library/6f2852b4-32ca-11ee-9fa9-06528b823331\">こちらのコラム</a>を読んで、よく噛んでゆっくり食べるコツをつかもう！\n\n"
    },
    "drinking": {
      "up": "飲酒のスコアは9月より%d点上がりました。<br>\nお酒を減らすと、集中力や仕事のパフォーマンスがUPする効果が期待できます。何か変化はありましたか？\n\n",
      "down": "飲酒のスコアは9月より%d点下がりました。<br>\nどんな時にお酒を飲み過ぎてしまいますか？自分ログの今日のメモも上手く活用しましょう。\n\n",
      "equal": "飲酒のスコアは9月と同じ点数です。<br>\nお酒を減らすと、集中力や仕事のパフォーマンスがUPする効果が期待できます。\n\n"
    },
    "sleep": {
      "up": "睡眠のスコアは9月より%d点上がりました。<br>\n快適な睡眠をとるために、寝室の<b>湿度を50％程度</b>に保ちましょう。乾燥による喉や肌への負担も少なくなります。\n\n",
      "down": "睡眠のスコアは9月より%d点下がりました。<br>\n快適な睡眠をとるために、寝室の<b>湿度を50％程度</b>に保ちましょう。乾燥による喉や肌への負担も少なくなります。\n\n",
      "equal": "睡眠のスコアは9月と同じです。<br>\n快適な睡眠をとるために、寝室の<b>湿度を50％程度</b>に保ちましょう。乾燥による喉や肌への負担も少なくなります。\n\n"
    },
    "stress": {
      "up": "ストレスのスコアは9月より%d点上がりました。<br>\n湯船に浸かり、身体を芯から温めてください。お好みの入浴剤を使い、お風呂時間を楽しむのも良いですね♪\n\n",
      "down": "ストレスのスコアは9月より%d点下がりました。<br>\n湯船に浸かり、身体を芯から温めてください。お好みの入浴剤を使い、お風呂時間を楽しむのも良いですね♪\n\n",
      "equal": "ストレスのスコアは9月と同じ点数です。<br>\n湯船に浸かり、身体を芯から温めてください。お好みの入浴剤を使い、お風呂時間を楽しむのも良いですね♪\n\n"
    }
  },
  "en": {
    "score": {
      "up": "Your lifestyle Score has gone up by %d points since September. Keep up the good work! There are only two months left in the year, so keep those healthy habits going!\r\nNovember's theme is, \"Mind and Body against the Cold.\" It's that season again when the cold causes more and more problems. Coldness can cause changes in blood pressure, stress, and other problems. Let's get through the winter by warming up both the body and mind.\n\n",
      "down": "Your lifestyle score has dropped by %d points since September. There are only two months left in the year, so get to work before the year is up! Let's stay healthy and get in shape.\nNovember's theme is, \"Mind and Body against the Cold.\" It's that season again when the cold causes more and more problems. Coldness can cause changes in blood pressure, stress, and other problems. Let's get through the winter by warming up both the body and mind.\n\n",
      "equal100": "Your Lifestyle score is perfect, just as it was in September. Great work! There are only two months left in the year, so keep those healthy habits going! <br>November's theme is, \"Mind and Body against the Cold.\" It's that season again when the cold causes more and more problems. Coldness can cause changes in blood pressure, stress, and other problems. Let's get through the winter by warming up both the body and mind.\n\n",
      "equal": "Your Lifestyle score is the same as it was in September. There are only two months left in the year, so get to work before the year is up! Let's stay healthy and get in shape.<br>\nNovember's theme is, \"Mind and Body against the Cold.\" It's that season again when the cold causes more and more problems. Coldness can cause changes in blood pressure, stress, and other problems. Let's get through the winter by warming up both the body and mind.\n\n"
    },
    "steps": {
      "up": "Your steps score has gone up %d pts since September.<br>\nKeep it going! Walking keeps the muscles in your body moving and helps promote blood flow.\n\n",
      "down": "Your steps score has gone down %d pts since September. <br>\nTo improve your health, why don't you try making an effort to increase the number of steps you take each day by 1,000 steps (about 10 minutes) from the number of steps you take now?\n\n",
      "equal": "Your steps score is the same as September. <br>\nWalking keeps the muscles in your body moving and helps promote blood flow.\n\n"
    },
    "exercise": {
      "up": "Your exercise score has gone up %d pts since September.<br>\nAre you enjoying this autumn of sports to the fullest? In addition to making your body and muscles stronger, exercising also has an effect on your mental health. It can give you a change of pace, relieve stress, and more.\n\n",
      "down": "Your exercise score has gone down %d pts since September.<br>\nExercise has an effect on your mental health, too—relieving stress is one of them! Aerobic exercise, such as walking, is said to be especially great for relieving stress.\n\n",
      "equal": "Your exercise score is the same as September.<br>\nExercise has an effect on your mental health, too—relieving stress is one of them! Aerobic exercise, such as walking, is said to be especially great for relieving stress.\n\n"
    },
    "meal": {
      "up": "Your food score has gone up %d pts since September.<br>\nChewing thoroughly is another way to keep cool. Read <a href=\"https://go.andwell.jp/library/6f2852b4-32ca-11ee-9fa9-06528b823331\">this column</a> to learn how to chew thoroughly and eat slowly!\n\n",
      "down": "Your food score has gone down %d pts since September.<br>\nChewing thoroughly is another way to keep cool. Read <a href=\"https://go.andwell.jp/library/6f2852b4-32ca-11ee-9fa9-06528b823331\">this column</a> to learn how to chew thoroughly and eat slowly!\n\n",
      "equal": "Your food score is the same as September.<br>\nChewing thoroughly is another way to keep cool. Read <a href=\"https://go.andwell.jp/library/6f2852b4-32ca-11ee-9fa9-06528b823331\">this column</a> to learn how to chew thoroughly and eat slowly!\n\n"
    },
    "drinking": {
      "up": "Your alcohol score has gone up %d pts since September.<br>\nReducing alcohol consumption improves concentration and work performance. Have you noticed any changes?\n\n",
      "down": "Your alcohol score has gone down %d pts since September.<br>\nAt what times do you end up drinking too much alcohol? Make good use of today's notes in your personal log.\n\n",
      "equal": "Your alcohol score is the same as September.<br>\nReducing alcohol consumption improves concentration and work performance.\n\n"
    },
    "sleep": {
      "up": "Your sleep score has gone up %d pts since September.<br>\nKeep the humidity in your bedroom at <b>around 50%</b> for a good night's sleep. This also makes the dryness less harsh on your throat and skin.\n\n",
      "down": "Your sleep score has gone down %d pts since September.<br>\nKeep the humidity in your bedroom at <b>around 50%</b> for a good night's sleep. This also makes the dryness less harsh on your throat and skin.\n\n",
      "equal": "Your sleep score is the same as September.<br>\nKeep the humidity in your bedroom at <b>around 50%</b> for a good night's sleep. This also makes the dryness less harsh on your throat and skin.\n\n"
    },
    "stress": {
      "up": "Your stress score has gone up %d pts since September.<br>\nSoak in the bathtub and warm your body from its core. You can also try using your favorite bath salts and enjoy your time in the bath♪.\n\n",
      "down": "Your stress score has gone down %d pts since September.<br>\nSoak in the bathtub and warm your body from its core. You can also try using your favorite bath salts and enjoy your time in the bath♪.\n\n",
      "equal": "Your stress score is the same as September.<br>\nSoak in the bathtub and warm your body from its core. You can also try using your favorite bath salts and enjoy your time in the bath♪.\n\n"
    }
  }
}